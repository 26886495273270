//
//  Tooltip
//

// Base
.tooltip {
  .tooltip-inner {
    box-shadow: $popover-box-shadow;
  }

  &.tooltop-auto-width {
    .tooltip-inner {
      white-space: nowrap;
      max-width: none;
    }
  }

  // Skins
  // Dark skin
  &.tooltip-dark {
    $m-bg-color: #2c2e3e;
    $m-font-color: #fff;

    .tooltip-inner {
      color: $m-font-color;
      background: #{$m-bg-color};
    }

    &.bs-tooltip-top {
      .arrow::before {
        border-top-color: #{$m-bg-color};
      }
    }

    &.bs-tooltip-right {
      .arrow::before {
        border-right-color: #{$m-bg-color}#{'/*rtl:ignore*/'};
      }
    }

    &.bs-tooltip-bottom {
      .arrow::before {
        border-bottom-color: #{$m-bg-color};
      }
    }

    &.bs-tooltip-left {
      .arrow::before {
        border-left-color: #{$m-bg-color}#{'/*rtl:ignore*/'};
      }
    }
  }

  // Brand skin
  &.tooltip-brand {
    $m-bg-color: kt-brand-color();
    $m-font-color: kt-brand-color(inverse);

    .tooltip-inner {
      color: $m-font-color;
      background: #{$m-bg-color};
    }

    &.bs-tooltip-top {
      .arrow::before {
        border-top-color: #{$m-bg-color};
      }
    }

    &.bs-tooltip-right {
      .arrow::before {
        border-right-color: #{$m-bg-color}#{'/*rtl:ignore*/'};
      }
    }

    &.bs-tooltip-bottom {
      .arrow::before {
        border-bottom-color: #{$m-bg-color};
      }
    }

    &.bs-tooltip-left {
      .arrow::before {
        border-left-color: #{$m-bg-color}#{'/*rtl:ignore*/'};
      }
    }
  }
}
