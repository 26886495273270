//
// Override Bootstrap Variables
//

$enable-rounded: $kt-rounded; // pass rounded corners setting to bootstrap SASS

// Fonts
$font-family-base: kt-get($kt-font-families, regular);

// Color System
$primary: kt-get($kt-state-colors, primary, base) !default;
$secondary: #e1e1ef !default;
$success: kt-get($kt-state-colors, success, base) !default;
$info: kt-get($kt-state-colors, info, base) !default;
$warning: kt-get($kt-state-colors, warning, base) !default;
$danger: kt-get($kt-state-colors, danger, base) !default;
$light: #f8f9fa !default;
$dark: #343a40 !default;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
);

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: $kt-media-breakpoints !default;

// Border Radiues
$border-radius: 0.25rem !default;
$border-radius-lg: 0.3rem !default;
$border-radius-sm: 0.2rem !default;

// Form & Button Controls
$btn-font-weight: normal !default;
$input-plaintext-color: kt-base-color(label, 3) !default;
$input-placeholder-color: kt-base-color(label, 2) !default;

$btn-secondary-color: kt-base-color(label, 3) !default;
$btn-secondary-hover-bg-color: #f4f5f8 !default;
$input-border-radius: $kt-border-radius !default;
$input-btn-padding-y: 0.65rem;
$input-btn-padding-x: 1rem;
$input-btn-line-height: 1.5;

$input-btn-padding-y-sm: 0.5rem;
$input-btn-padding-x-sm: 1rem;
$input-btn-line-height-sm: 1.5;

$input-btn-padding-y-lg: 1.15rem;
$input-btn-padding-x-lg: 1.65rem;
$input-btn-line-height-lg: 1.5;

$input-border-color: kt-base-color(grey, 3) !default;
$input-focus-border-color: lighten(kt-state-color(brand, base), 12%);
$input-disabled-bg: kt-base-color(grey, 1) !default;
$input-readonly-bg: #ffffff !default;
$input-group-icon-color: kt-base-color(label, 2) !default;

$input-group-addon-color: kt-base-color(label, 2) !default;
$input-group-addon-bg: kt-base-color(grey, 1) !default;

// Button elevate shadows
$btn-elevate-shadow-1: 0px 0px 11px 0px rgba(56, 36, 99, 0.08);
$btn-elevate-shadow-1-hover: 0px 0px 22px 0px rgba(56, 36, 99, 0.11);
$btn-elevate-shadow-2: 0px 0px 11px 0px rgba(56, 36, 99, 0.1);
$btn-elevate-shadow-2-hover: 0px 0px 22px 0px rgba(56, 36, 99, 0.13);
$btn-elevate-shadow-3: 0px 0px 11px 0px rgba(56, 36, 99, 0.12);
$btn-elevate-shadow-3-hover: 0px 0px 22px 0px rgba(56, 36, 99, 0.15);

// Table
$table-bg: transparent !default;
$table-accent-bg: lighten(#f4f5f8, 1%);
$table-hover-bg: lighten(#f4f5f8, 2%);
$table-active-bg: kt-state-color(primary, base);

$table-border-color: kt-base-color(grey, 2);

$table-head-bg: kt-base-color(grey, 2);
$table-head-color: kt-base-color(label, 3);

// Card
$card-border-color: kt-base-color(grey, 2) !default;
$card-cap-bg: kt-base-color(grey, 1) !default;

// List group
$list-group-border-color: kt-base-color(grey, 2) !default;
$list-group-hover-bg: kt-base-color(grey, 2) !default;

// Modal
$modal-inner-padding: 1.25rem !default;
$modal-header-padding: 1.25rem !default;

$modal-content-border-color: kt-base-color(grey, 2) !default;
$modal-content-box-shadow-xs: 0 0.25rem 0.5rem rgba(kt-base-color(grey, 2), 0.5) !default;
$modal-content-box-shadow-sm-up: 0 0.5rem 1rem rgba(kt-base-color(grey, 2), 0.5) !default;

$modal-header-border-color: kt-base-color(grey, 2) !default;
$modal-footer-border-color: kt-base-color(grey, 2) !default;

// Pagination
$pagination-color: kt-state-color(brand, base) !default;
$pagination-border-color: kt-base-color(grey, 2) !default;

$pagination-hover-bg: kt-base-color(grey, 2) !default;
$pagination-hover-border-color: kt-base-color(grey, 2) !default;

$pagination-disabled-border-color: kt-base-color(grey, 2) !default;

// Popovers
$popover-font-size: 0.9rem !default;
$popover-max-width: 276px !default;
$popover-border-width: 1px !default;
$popover-border-color: #ffffff !default;
$popover-border-radius: 3px !default;
$popover-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15) !default;

$popover-header-bg: #ffffff !default;
$popover-header-color: kt-base-color(label, 3) !default;
$popover-header-padding-y: 1rem !default;
$popover-header-padding-x: 1.25rem !default;

$popover-body-color: kt-base-color(label, 2) !default;

// Tooltips
$tooltip-font-size: 0.9rem !default;
$tooltip-max-width: 200px !default;
$tooltip-color: kt-base-color(label, 3) !default;
$tooltip-bg: #ffffff !default;
$tooltip-border-radius: 3px !default;
$tooltip-opacity: 1 !default;
$tooltip-padding-y: 0.75rem !default;
$tooltip-padding-x: 1rem !default;
$tooltip-margin: 0 !default;

// Custom Toasts
$toast-zindex: $kt-dropdown-zindex + 100 !default;
$toast-spacing: 2rem !default;
$toast-width: 350px !default;
$toast-box-shadow: $kt-dropdown-shadow !default;

$toast-header-color: kt-base-color(label, 3) !default;
$toast-header-background-color: #fff !default;
$toast-header-border-color: kt-base-color(grey, 2) !default;

// Progress bars
$progress-bg: kt-get-color() !default;

// Dropdown
$dropdown-box-shadow: $kt-dropdown-shadow !default;
$zindex-dropdown: $kt-dropdown-zindex !default;

// Text muted
$text-muted: kt-base-color(label, 2) !default;

// Badges
$badge-padding-y: 0.5em !default;
$badge-padding-x: 0.75em !default;
$badge-font-size: 85% !default;

// Grid columns
$grid-gutter-width: 20px !default;

// Dropdowns
//
// Dropdown menu container and contents.
$dropdown-link-color: kt-base-color(label, 2) !default;
$dropdown-link-hover-color: kt-base-color(label, 3) !default;
$dropdown-link-hover-bg: kt-base-color(grey, 1) !default;

$dropdown-link-active-color: kt-brand-color(inverse) !default;
$dropdown-link-active-bg: kt-brand-color() !default;

$dropdown-link-disabled-color: kt-base-color(label, 1) !default;

$dropdown-header-color: kt-base-color(label, 2) !default;
