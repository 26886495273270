//
// Header
//

// Desktop mode(1024px and above)
@include kt-desktop() {
  // Header
  .kt-header {
    display: flex;
    align-items: stretch;
    transition: height 0.3s ease;
    position: relative;
    z-index: 2;
    height: kt-get($kt-header-config, base, desktop, default, height);
    box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
    background: var(--blanc);

    // Container
    .kt-container {
      display: flex;
      align-items: stretch;

      &:before,
      &:after {
        display: none !important;
      }

      // Default logo
      .kt-header__brand-logo-default {
        display: inline-block;
      }

      // Sticky logo
      .kt-header__brand-logo-sticky {
        display: none;
      }
    }

    // Fixed header mode
    .kt-header--fixed & {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: kt-get($kt-header-config, base, desktop, fixed, zindex);
      height: kt-get($kt-header-config, base, desktop, default, height);
      box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
      background: var(--blanc);
    }

    // Fixed and minimized header mode
    .kt-header--fixed.kt-header--minimize & {
      transition: height 0.3s ease;
      height: kt-get($kt-header-config, base, desktop, fixed, height);
    }
  }
}
