/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '~material-design-icons/iconfont/material-icons.css';
@import '~highlight.js/styles/googlecode.css';
@import "colors";
// hide bootstrap dropdown arrow globally
.dropdown-toggle::after {
  display: none !important;
}

.mat-table__wrapper--dashboard .mat-table {
  min-width: 600px !important;
  width: 100% !important;
}

.position-static {
  position: static;
}
.main-button {
  background-color: var(--bleu-de-france) !important;
  color: var(--blanc) !important;
}

.mat-menu-item .mat-icon {
  margin-top: 8px;
}
.main-button {
  background-color: var(--bleu-de-france) !important;
  color: var(--blanc) !important;
}
