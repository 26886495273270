//
// Notification
//

$kt-notification-item-padding-x: 1.5rem;
$kt-notification-item-padding-y: 1.1rem;

.kt-notification {
  // Item
  .kt-notification__item {
    display: flex;
    align-items: center;
    padding: $kt-notification-item-padding-y $kt-notification-item-padding-x;
    border-bottom: 1px solid kt-base-color(grey, 1);
    transition: background-color 0.3s ease;

    &:last-child {
      border-bottom: 1px solid transparent;
    }

    .kt-notification__item-icon {
      display: flex;
      flex: 0 0 2.5rem;
      align-items: center;

      i {
        font-size: 1.4rem;
        color: kt-brand-color();
      }

      svg {
        height: 24px;
        width: 24px;
        margin-left: -4px;
      }
    }

    .kt-notification__item-details {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .kt-notification__item-title {
        transition: color 0.3s ease;
        font-size: 1rem;
        font-weight: 400;
        color: kt-base-color(label, 3);
      }

      .kt-notification__item-time {
        font-size: 1rem;
        font-weight: 300;
        color: kt-base-color(label, 2);
      }
    }

    &:hover {
      transition: background-color 0.3s ease;
      text-decoration: none;
      background-color: kt-base-color(grey, 1);

      .kt-notification__item-details {
        .kt-notification__item-title {
          transition: color 0.3s ease;
          color: kt-brand-color();
        }
      }
    }

    &.kt-notification__item--read {
      & {
        opacity: 0.5;
      }
    }

    &:after {
      border: 0;
      @include kt-arrow-icon(right, true);
      font-size: 0.8rem;
      line-height: 0;
      position: relative;
      color: kt-base-color(label, 2);
    }
  }

  // Custom
  .kt-notification__custom {
    display: flex;
    align-items: center;
    border-top: 1px solid kt-base-color(grey, 1);
    padding: ($kt-notification-item-padding-y + 0.5)
      $kt-notification-item-padding-x;
  }

  &.kt-notification--fit {
    .kt-notification__item {
      padding: $kt-notification-item-padding-y / 2 0;

      &:hover {
        background-color: transparent;

        .kt-notification__item-title {
          color: kt-brand-color();
        }
      }
    }
  }
}

// Util classes
.kt-notification-item-padding-x {
  padding-left: $kt-notification-item-padding-x !important;
  padding-right: $kt-notification-item-padding-x !important;
}

.kt-notification-item-margin-x {
  margin-left: $kt-notification-item-padding-x !important;
  margin-right: $kt-notification-item-padding-x !important;
}
