// Tables
.mat-table__wrapper {
  width: 100%;
  overflow-y: auto;

  mat-cell,
  mat-header-cell {
    min-height: 100%;
  }

  .mat-table {
    min-width: 1000px;

    .mat-cell,
    .mat-footer-cell,
    .mat-header-cell {
      padding-right: 10px;
    }

    .mat-cell:last-child,
    .mat-footer-cell:last-child,
    .mat-header-cell:last-child {
      padding-right: 0;
    }

    .mat-row {
      transition: padding 0.3s ease;
    }

    .mat-row-editing {
      transition: padding 0.3s ease;
      padding: 12px 0 !important;

      .mat-form-field-fluid {
        width: 99% !important;
      }
    }
  }

  .mat-column-actions {
    .mat-column-actions-wrapper {
      display: flex;
      align-items: center;
    }
    .mat-spinner {
      margin-left: 12px;
      margin-right: 8px;
    }
  }

  .mat-table__message {
    padding: 30px !important;
    text-align: center;
    font-weight: 500;
  }

  .mat-column-checkbox {
    padding-left: 20px;

    .mat-checkbox {
      .mat-checkbox-layout {
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
      }
    }
  }

  .ps__rail-y {
    z-index: 100;
  }
}

.mat-table__wrapper .mat-table {
  min-width: 1000px;
  width: 100%;
}

.mat-table__wrapper--dashboard .mat-table {
  min-width: 600px !important;
}

.mat-table__wrapper .mat-table .email-cell {
  word-wrap: normal;
  width: 180px;
  flex: auto;
}

.mat-table__wrapper--sub .mat-table {
  min-width: 400px;

  .mat-header-cell:last-child {
    flex: 0 0 100px;
  }
}

.mat-table-sticky {
  z-index: 97 !important;
}

.mat-table__bottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mat-column-checkbox {
  flex: 0 0 68px;
}

.mat-column-id {
  flex: 0 0 68px;
}
.mat-column-commande{
  flex: 0 0 170px;
}
.mat-column-status{
  flex: 0 0 100px;

}
.mat-column-vu{
  flex: 0 0 130px;
  align-items: flex-end;
}
.mat-column-main {
  flex: 0 0 568px;
}

.mat-column-actions {
  flex: 0 0 140px;
}

.mat-column-dAt {
  flex: 0 0 130px;
}
.mat-column-ref {
  flex: 0 0 100px;
}

.mat-column-contact {
  flex: 0 0 285px;
}

.mat-column-supplier {
  flex: 0 0 120px;
}


.mat-column-key {
  flex: 0 0 360px;
}
.mat-column-client {
  flex: 0 0 200px;
}
.mat-column-btn {
  flex: 0 0 220px;
}

.mat-table__bottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mat-column-vinCode {
  flex: 0 0 150px;
}

.mat-column-vinCode-date {
  flex: 0 0 90px;
}


.mat-table__wrapper .mat-table .mat-row-editing.mat-row-inactive {
  height: 0;
  min-height: 0;
  padding: 0 !important;
  border-bottom: 0 !important;
}
