//
// Footer Config
//

$kt-footer-config: (
  self: (
    fixed: (
      zindex: 97,
      height: 60px,
    ),
  ),
);
