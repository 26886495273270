//
// Searchbar
//

.kt-searchbar {
  .input-group {
    .input-group-text {
      border: none;
      background-color: var(--solitude);
      padding: 0 0.65rem 0 1rem;

      .kt-svg-icon {
        height: 20px;
        width: 20px;
      }

      i {
        font-size: 1rem;
      }
    }

    .form-control {
      border: none;
      background-color: var(--solitude);
      padding: 0;
    }
  }

  .kt-input-icon {
    .form-control {
      border: none;
      background-color: var(--solitude);
    }

    .kt-input-icon__icon {
      .kt-svg-icon {
        height: 20px;
        width: 20px;
      }
    }
  }
}
