:root {
    --main-color-primary: #d50000;
    --noir:#000;
    --noir-ba : #000000ba;
    --bleu-pigment: #023382;
    --blanc-anti-flash-3: #4f4f51;
    --whisper: #e7e2e2;
    --whisper-2: #eaeaea;
    --pale-grey: #f2f2f4;
    --bleu-de-france: #d50000;
    --black05: #0000000d;
    --black10: #0000001a;
    --black15: #00000026;
    --black80:#000000cc;
    --blanc: #ffffff;
    --blanc00: #ffffff00;
    --blanc-015: #ffffff04;
    --blanc10:#ffffff1a;
    --blanc20:#ffffff33;
    --blanc50: #ffffff80;
    --blanc30:#ffffff4d;
    --blanc40:#ffffff66;
    --blanc60:#ffffff99;
    --blanc-70: #ffffffb3;
    --blanc80: #ffffffcc;
    --blanc90:#ffffffe6;
    --purple40: #4322a766;
    --purple80: #4322a7cc;
    --thimbleberry: #e54e4e;
    --pinkish-red: #e0306e;
    --medium-dark-shade-of-gray: #555;
    --medium-dark-shade-of-gray-2: #444;
    --dark-charcoal: #333;
    --shade-of-grey: #888;
    --very-dark-shade-of-gray: #222;
    --very-light-shade-of-gray: #e6e6e6;
    --very-light-shade-of-gray-2: #eee;
    --very-light-shade-of-gray-2: #ddd;
    --medium-light-shade-of-gray-2: #bbb;
    --white-smoke: #f8f7f7;
    --white-smoke-2: #f8f7f7;
    --white-smoke-3: #f4fcfc;
    --white-smoke-origin: #f5f5f5;
    --dark-gray: #b0adad;
    --dark-gray-2: #666;
    --gray91: #e8e8e8;
    --gray94: #f0f0f0;
    --moon-yellow: #ffb822;
    --screaming-blue: #3d4aed;
    --blanc-pantone: #ebedf2;
    --blakish-purple:#d50000;
    --very-light-blue: #459BD4;
    --iris-blue: #00AADC;
    --amethist-smoke: #9392a0;
    --shade-of-purple: #3850a8a6;
    --shade-of-slate-grey: #d50000;
    --shade-of-lite-grey: #c8cde8;
    --powdered-granite: #cdd1e9;
    --mysterious-night: #5d5b6f;
    --mysterious-night-2: #5e5b70;
    --ghost-white: #f4f4f9;
    --ghost-white-2: #f7f8fc;
    --ghost-white-3: #fafbff;
    --ghost-white-4: #fcfcfe;
    --ghost-white-5: #f8f7fd;
    --ghost-white-6: #f7f7f9;
    --ghost-white-6-40: #f7f7f966;
    --ghost-white-6-70: #f7f7f9b3;
    --ghost-white-7: #f8f8fb;
    --ghost-white-7-05: #f8f8fb0d;
    --ghost-white-8: #f0f3ff;
    --ghost-white-9: #eaecf2;
    --ghost-white-10: #f4f4f8;
    --ghost-white-11: #f9fafe;
    --star-dust: #9492a1;
    --paua: #b3b1c2;
    --solitude: #f2f3f7;
    --solitude-2: #ebecf1;
    --bleuish-white: #e6eaf5;
    --bleuish-green: #6ed8dd;
    --blanc-lilac: #e7e9f5;
    --bleish-purple-shade: #93a2dd;
    --alive-bleu-2: #e1f1ff;
    --pigment-green: #84d49e;
    --alizarine-crimson: #f23543;
    --closet-shaddow: #fdca2a;
    --honey: #f19819;
    --grass-green: #46b450;
    --greenish-blue: #29afa1;
    --orange-honey: #fb7575;
    --slate-blue: #795ecd;
    --cultured: #f4f4f4;
    --fuchsia-purple: #d93371;
    --mantaee-blue: #696b84;
    --alice-bleu: #f4fcff;
    --alice-bleu-2: #f7f8fa;
    --alice-bleu-3: #edf0fc;
    --deep-sky-blue: #22b9ff;
    --mid-gray: #585a62;
    --sleet: #93959e;
    --purple-blue: #bbabf1;
    --shade-of-silver :#b5b2c3;
    --dim-gray: 67666e;
    --shade-of-fuchsia: #fe21be;
    --violet-blue: #314da7;
    --dark-orange: #cc6622;
    --summer-sky: #a3dcf0;
    --charade: #272631;
    --jordy-blue: #8aa4f3;
    --contrast-shade-of-silver: #b5b2c8;
    --raven: #676c7b;
    --lavender: #f0f0f4;
    --lavender-gray: #cacad2;
    --lavender-gray-2: #c4c5d6;
    --bleuish-gray: #5f6281;
    --bleuish-gray-2: #8d8c9b;
    --bleuish-gray-3: #aab1df;
    --manatee: #928eaa;
    --pastel-green: #586bd8;
    --shammrock: #34bfa3;
    --carnation: #f4516c;
    --royal-blue: #5867dd;
    --lulena: #8a0dff;
    --prim: #d2d8e8;
    --medium-sea-green: #57c974;
    --cadet-blue: #a1a8c3;
    --cool-gray: #959cb6;
    --navy: #e7e8ef;
    --periwinkle: #c5cbe7;
    --paynes-gray: #434349;
    --pastel-pink: #968ad4;
    --minsk: #9e9bb1;
    --pastel-gray: #b2afc6;
    --witches-cauldron: #33323f;
    --approx-nero: #242424;
    --medium-light-shade-of-gray-3: #aaa;
    --very-light-blue-2: #6CB5F4;
    --dark-gray11: #a7a6aa;
}

